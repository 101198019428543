import React, { memo, useCallback, useMemo , useState, useEffect} from "react";
import { ButtonIcon, FormActions, InfoButton, Submit, Wrap } from "./Styles";
import FormikSelect from "../Fields/FormikSelect";
import { ArrowNextIcon, InfoTextIcon, LoaderIcon, SwapIcon } from "../icons";
import FormikDatePicker from "../Fields/FormikDatePicker";
import RadioSelect from "../Fields/RadioSelect";
import FormikInput from "../Fields/FormikInput";
import PrivacyInput from "../Fields/PrivacyInput";
import PhoneInputField from "../Fields/FormikPhone";


const ReservationFormFields = memo(
  ({
    isSubmitting = false,
    routesAutocomplete = {},
    routeFrom = "",
    routeTo = "",
    setFieldValue,
    sent
  }) => {

    const optionsFrom = useMemo(() => {
      return Object.keys(routesAutocomplete).map((key) => ({
        label: key,
        value: key,
      }));
    }, [routesAutocomplete]);
    const optionsTo = useMemo(() => {
      let options = Object.keys(routesAutocomplete);
      if (!!routeFrom) {
        options = routesAutocomplete[routeFrom] || [];
      }
      return options.map((key) => ({ label: key, value: key }));
    }, [routesAutocomplete, routeFrom]);

    const onSwapRoute = useCallback(() => {
      setFieldValue("from", routeTo);
      setFieldValue("to", routeFrom);
    }, [routeTo, routeFrom, setFieldValue]);




    return (
      <Wrap className="field-wrap">
        <h2>Get optimal travel route offer for freight transport</h2>
        <div className="select-wrap flex-fill">
          <FormikSelect placeholder="From" options={optionsFrom} name="from" />
          <ButtonIcon type="button" onClick={onSwapRoute}>
            <SwapIcon className="icon" />
          </ButtonIcon>
          <FormikSelect placeholder="To" options={optionsTo} name="to" />
        </div>
        <FormikDatePicker placeholder="Date" name="date" />
        <RadioSelect />
        <div className="field-group">
          <FormikInput type="text" label="Company name" name="company" />
          <FormikInput
            type="text"
            label="Contact person"
            name="contact_person"
          />
        </div>
        <div className="field-group">
          <div style={{position: 'relative'}}>
          <PhoneInputField name='phone' type='text'/>
          </div>
          <FormikInput type="email" label="Email Address" name="email" />
        </div>
        <PrivacyInput />
        <FormActions>
          <Submit className="flex-fill" type="submit">
            {sent ? (
              <>
                <span>Quote sent! Offer will be sent to you soon</span>
              </>
            ) : isSubmitting ? (
              <>
                <LoaderIcon className="spinner" />
                <span>Processing ...</span>
              </>
            ) : (
              <>
                <span>Get a qoute in 15 min</span>
                <ArrowNextIcon className="icon" />
              </>
            )}
          </Submit>
          <InfoButton data-tip="On work days from 8 AM to 5 PM we will answer your query within 15 minutes. In other times we will get back to you as soon as possible. To calls we respond 24/7.">
            <InfoTextIcon className="icon" />
          </InfoButton>
        </FormActions>
      </Wrap>
    );
  }
);
export default ReservationFormFields;
