import React, { useEffect, useState } from "react";
import Select from "react-select";
import Input from "../../components/Register/RegisterInput";
import euCountries from "../../data/EUcountries.json";
import countries from "../../data/countries.json";
import { Link } from "react-router-dom";


import axios from "axios";
import { isMobile } from "react-device-detect";
import { API_URL } from "../../constants";

const RegisterStepTwo = (props) => {
  const { vatNumber } = props.inputs;
  const [isCompanyNameDisabled, setIsCompanyNameDisabled] = useState(false);
  useEffect(() => {
    if (vatNumber && vatNumber?.length >= 2) {
      if (vatNumber.substr(0, 2).match(/[0-9]/i)) {
        props.setErrors((prevState) => ({
          ...prevState,
          vatNumber: {
            msg: "Enter a valid VAT number",
          },
        }));
      } else {
        props.setErrors((prevState) => ({
          ...prevState,
          vatNumber: null,
        }));
      }
      let countryCode = vatNumber.substr(0, 2).toUpperCase();
      if (vatNumber?.length > 7) {
        for (var i = 0; i < europeanCountries?.length; i++) {
          if (europeanCountries[i].code === countryCode) {
            //Wait until VAT code valid minimum lenght then run api
            if (vatNumber?.length > 9) {
              axios({
                method: "GET",
                url: `${API_URL}/vatCheck`,
                params: {
                  vatid: vatNumber.toString().replace(/\s/g, ""),
                },
              }).then((res) => {
                if (res.data.validation.valid) {
                  if (res.data.validation.company_name !== "---") {
                    props.setInputs((prevState) => ({
                      ...prevState,
                      companyName: res.data.validation.company_name,
                    }));
                    setIsCompanyNameDisabled(true);
                    props.setErrors((prevState) => ({
                      ...prevState,
                      vatNumber: null,
                    }));
                  }
                  for (var i = 0; i < europeanCountries?.length; i++) {
                    if (europeanCountries[i].code === res.data.country_code) {
                      const newCountry = europeanCountries[i].country;
                      props.setInputs((prevState) => ({
                        ...prevState,
                        companyName:
                          res.data.validation.company_name !== "---"
                            ? res.data.validation.company_name
                            : prevState.companyName,
                        country: newCountry,
                      }));
                    }
                  }
                } else {
                  props.setErrors((prevState) => ({
                    ...prevState,
                    vatNumber: {
                      msg: "Enter a valid VAT number",
                    },
                  }));
                }
              });
            }
          }
        }
      }
    } else {
      props.setInputs((prevState) => ({
        ...prevState,
        companyName: '',
        country: '',
      }));
      setIsCompanyNameDisabled(false);
    }
  }, [vatNumber]);

  // handle select changes

  const handleSelectChange = (e, name) => {
    props.setInputs({ ...props.inputs, [name]: e.value });
    props.setErrors({ ...props.errors, [name]: null });
  };

  return (
    <div
      className={`Registration__part--PartSecond ${props.pagination === 2 && "active"
        }`}
    >
      <h3>Step 2. Company details</h3>
      {isMobile && <p>Fill form with your company info</p>}
      <Input
        classes={`floating-label ${props.errors?.companyName && "invalid"}`}
        name="companyName"
        type="text"
        value={props.inputs?.companyName || ""}
        onChange={(e) => props.handleInputChange(e)}
        label="Company Name"
        error={props.errors?.companyName && props.errors?.companyName?.msg}
        isDisabled={isCompanyNameDisabled}
      />
      <Input
        classes={`floating-label ${props.errors?.vatNumber && "invalid"}`}
        name="vatNumber"
        type="text"
        value={props.inputs?.vatNumber || ""}
        onChange={(e) => props.handleInputChange(e)}
        label="VAT number (for example: DE123456789)"
        error={props.errors?.vatNumber && props.errors?.vatNumber?.msg}
      />
      <div className="dropdown-wrapper">
        <Select
          classNamePrefix="react-select"
          className={`dropdown ${props?.errors?.country && "invalid"}`}
          placeholder="Country"
          options={countrySelect}
          name="country"
          value={
            props.inputs?.country && {
              value: props.inputs?.country,
              label: props.inputs?.country,
            }
          }
          onChange={(e) => handleSelectChange(e, "country")}
        />
        {props.errors?.country ? (
          <div className="error-message">{props.errors?.country?.msg}</div>
        ) : null}
      </div>
      <Input
        classes={`floating-label ${props.errors?.address && "invalid"}`}
        name="address"
        type="text"
        value={props.inputs?.address || ""}
        onChange={(e) => props.handleInputChange(e)}
        label="Address"
        error={props.errors?.address && props.errors.address.msg}
      />
      <h4>Additional info</h4>
      <div className="dropdown-wrapper">
        <Select
          classNamePrefix="react-select"
          className={`dropdown ${props.errors?.companyType && "invalid"}`}
          placeholder="Company type"
          options={companyTypes}
          name="companyType"
          value={
            props.inputs?.companyType && {
              value: props.inputs?.companyType,
              label: props.inputs?.companyType,
            }
          }
          onChange={(e) => handleSelectChange(e, "companyType")}
        />
        {props.errors?.companyType ? (
          <div className="error-message">{props.errors?.companyType?.msg}</div>
        ) : null}
      </div>
     
     
      <h4>Contact info</h4>
      <Input
        classes={`floating-label ${props.errors?.website && "invalid"}`}
        name="website"
        type="text"
        value={props.inputs?.website || ""}
        onChange={(e) => props.handleInputChange(e)}
        label="Website (optional)"
        error={props.errors?.website && props.errors?.website?.msg}
      />
      <Input
        classes={`floating-label ${props.errors?.companyEmail && "invalid"}`}
        name="companyEmail"
        type="text"
        value={props.inputs?.companyEmail || ""}
        onChange={(e) => props.handleInputChange(e)}
        label="Company Email address"
        error={props.errors?.companyEmail && props.errors?.companyEmail?.msg}
      />
      
      <div className="inline-label termslinks">
        <label htmlFor="accept">
          <input
            type="checkbox"
            id="accept"
            name="accept"
            onChange={(e) => props.handleInputChange(e)}
          />
          I have read and agree with{" "}
          <span>
            <Link to="/terms" target="_blank">
              Terms & Conditions
            </Link>
          </span>{" "}
          and{" "}
          <span>
            <Link to="/privacy" target="_blank">
              Privacy Policy
            </Link>
          </span>
        </label>
        {props.errors?.accept && (
          <div className="error-message">{props.errors?.accept?.msg}</div>
        )}
      </div>
      <div className="formSubmit">
        <button
          type="button"
          className="next-step back"
          onClick={() => props.setPagination(1)}
        >
          Back
        </button>
        <div className="Submit__Wrapper">
          <button type="submit" className="next-step">
            Continue
          </button>
        </div>
      </div>
    </div>
  );
};

const companyTypes = [
  { value: "Haulier", label: "Haulier" },
  { value: "Forwarder", label: "Forwarder" },
  { value: "Producer", label: "Producer" },
];
const employeeSelect = [
  { value: "1-10", label: "1-10" },
  { value: "10-25", label: "10-25" },
  { value: "25-50", label: "25-50" },
  { value: "50-100", label: "50-100" },
  { value: "100+", label: "100+" },
];

const europeanCountries = JSON.parse(JSON.stringify(euCountries.counties));
const allCountries = JSON.parse(JSON.stringify(countries.counties));
// Add Countries to country select box
const countrySelect = [];
Object.keys(allCountries).forEach(function (key) {
  countrySelect.push({
    value: allCountries[key].country.toString(),
    label: allCountries[key].country.toString(),
  });
});


export default RegisterStepTwo;
