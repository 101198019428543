import React from "react";
import { useDispatch } from "react-redux";
import iNotifications from "../../../assets/icons/notifications.svg";

import Form from "../UI/Form";
import FormBlock from "../UI/FormBlock";

import "../../../styles/Dashboard/MyAccount/Notifications.css";
import Loader from "../UI/Loader";
import { changeUserNotifications } from "../../../store/actions/dashboard/user";
import ScreenBlock from "../Screen/ScreenBlock";

const Notifications = (props) => {
  const { USER } = props;
  const dispatch = useDispatch();

  return (
    <ScreenBlock title="Notifications" img={iNotifications}>
      <div className="Screen_Form Notifications">
        {USER?.notifications ? (
          <Form>
            <FormBlock title="Notify me via email when">
              {USER.notifications.map((notification) => {
                return (
                  <div className="toggle-wrapper" key={notification._id}>
                    <label htmlFor={notification.name}>
                      {notification.name}
                      <input
                        type="checkbox"
                        id={notification.name}
                        name={notification.name}
                        defaultChecked={notification.value}
                        onChange={(e) =>
                          dispatch(
                            changeUserNotifications(
                              notification._id,
                              e.target.checked
                            )
                          )
                        }
                      />
                      <div className="toggler"></div>
                    </label>
                  </div>
                );
              })}
            </FormBlock>
          </Form>
        ) : (
          <Loader />
        )}
      </div>
    </ScreenBlock>
  );
};
export default Notifications;
