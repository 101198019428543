import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "universal-cookie";
import logo from "../../images/logo.svg";
import "../../styles/Checkout/Checkout.css";
import { Link, withRouter } from "react-router-dom";
import da from "../../images/checkout-elem.svg";
import ds from "../../images/checkout-elems.svg";
import { isMobile } from "react-device-detect";
import { API_URL } from "../../constants";
import Container from "../UI/Container";
import { Button } from "../Buttons/NewButton/Styles";
import WithInvoice from "./WithInvoice";
import {
  getUser,
  getUserSubscription,
} from "../../store/actions/dashboard/user";

import {
  Elements,
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

const stripePromise = loadStripe(
  "pk_test_51OV6XsCRyO1C3s6F3BMnrCtXxXZ22eSkfJYGfZXHKGnYG69rzhvgy0ijA97QlyLTs5JQe4jrcNb4Pcwu287IES6400DcodEJB1"
);

const CheckoutForm = (props) => {
  const stripe = useStripe();
  const elements = useElements();

  const dispatch = useDispatch();

  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const errorMessageRef = useRef();

  useEffect(() => {
    if (!stripe) {
      return;
    }

    if (!props.clientSecret) {
      return;
    }

    stripe
      .retrievePaymentIntent(props.clientSecret, {
        expand: ["invoice"],
      })
      .then(({ paymentIntent }) => {
        switch (paymentIntent.status) {
          case "succeeded":
            console.log(paymentIntent);
            props.setTransactionCompleted(true);
            dispatch(getUserSubscription());
            break;
          case "processing":
            setMessage("Your payment is processing.");
            break;
          // case "requires_payment_method":
          //   setMessage("Your payment was not successful, please try again.");
          //   break;
          default:
            break;
        }
      });
  }, [stripe]);

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    //TODO change to production url
    // encode state from props to redirect url
    const returnUrl = `${window.location.origin}/checkout/success?duration=${props.billing}&plan=${props.plan}&price=${props.price}&subscriptionId=${props.subscriptionId}&currentMembershipId=${props.currentMembershipId}&gatewaySubscriptionId=${props.gatewaySubscriptionId}`;

    const result = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: returnUrl,
      },
    });

    if (result.error) {
      console.log(result.error.message);
      setMessage(result.error.message);
      setIsLoading(false);
      errorMessageRef.current.scrollIntoView({ behavior: "smooth" });
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };

  return (
    <>

      <form onSubmit={handleSubmit}>
        <PaymentElement />
        <Button disabled={isLoading || !stripe || !elements}>
          {/* TODO spinner */}
          {isLoading ? <div className="spinner" id="spinner">
            {spinner}
          </div> : "Pay"} 
        </Button>
        {(message && !isLoading) && <div id="payment-error-message" ref={errorMessageRef}>{message}</div>}
      </form>
    </>
  );
};

const Checkout = (props) => {
  const cookies = new Cookies(),
    tcToken = cookies.get("tc_token");

  const [pathName, setPathName] = useState("/pricing");

  const [clientSecret, setClientSecret] = useState("");

  const SUBSCRIPTION = useSelector(
    (state) => state.dashboard?.user?.subscription
  );

  useEffect(() => {
    if (props.location.pathname === "/checkout") {
      setPathName("/checkout");
      // CREATE SUBSCRIPTION ON STRIPE
      fetch(`${API_URL}/addSubscription`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${tcToken}`,
        },
        body: JSON.stringify({
          planId: props.subscriptionId,
          billing: props.billing,
          price: totalPriceRef.current,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          setClientSecret(data.clientSecret);
          setTotalPrice(data.price);
          totalPriceRef.current = data.price;
        })
        .catch((err) => console.log(err));
    }
  }, [props.location]);

  // restore state after redirect from stripe
  useEffect(() => {
    if (props.location.pathname === "/checkout/success") {
      setClientSecret(
        new URLSearchParams(window.location.search).get(
          "payment_intent_client_secret"
        )
      );
      // calculate next payment date
      setNextPayment(
        new Date(
          new Date().setMonth(
            new Date().getMonth() + parseInt(props.billing)
          )
        ).toLocaleDateString()
      );
      setTotalPrice(props.price);
    }
  }, [props.location]);

  let billingString;
  if (props.billing === "12") {
    billingString = "annualy";
  } else if (props.billing === "6") {
    billingString = "every 6 months";
  } else if (props.billing === "1") {
    billingString = "monthly";
  }
  const [error, setError] = useState(false);
  const [loader, setLoader] = useState(false);
  const [monthlyPrice, setMonthlyPrice] = useState(props.price);
  const [totalPrice, setTotalPrice] = useState(null);
  const [oldMonthlyPrice, setOldMonthlyPrice] = useState(props.price);
  const [oldTotalPrice, setOldTotalPrice] = useState(totalPrice);
  // const [discount, setDiscount] = useState("");

  const [hasDiscount, setHasDiscount] = useState(false);
  const [discountAmount, setDiscountAmount] = useState();
  // const [discountBorder, setDiscountBorder] = useState();
  // const [discountLoader, setDiscountLoader] = useState(false);
  const [transactionCompleted, setTransactionCompleted] = useState(false);
  const [nextPayment, setNextPayment] = useState();
  const [subscriptionId] = useState(props.subscriptionId);
  const totalPriceRef = useRef(totalPrice);
  // const discountSumRef = useRef(0);
  // const discountCodeRef = useRef("");
  const dispatch = useDispatch();

  //Current subscription info

  const [firstMpriceState] = useState(0);
  const [downgrade] = useState(false);

  // GET CURRENT SUBSCRIPTION

  const expirationDate = props.expirationDate;

  const [nextBilling] = useState(expirationDate);

  const [errorMessage, setErrorMessage] = useState(
    "Payment couldn't be processed. Please check if the information entered is correct."
  );

  if (!clientSecret) {
    return (
      <div className="Checkout">
        <div className="Checkout__Navbar">
          <Container>
            <header>
              <Link to="/" className="logo">
                <img src={logo} alt="TransConnector" />
              </Link>
            </header>
          </Container>
        </div>
        <div className="Checkout__Steps">
          {!transactionCompleted ? (
            <>
              <Link to={pathName}>
                <div className="Step--completed">{check}</div>
                <div className="Step completed">
                  <h4>Select Plan</h4>
                </div>
              </Link>
              <div className="Step--arrow">{arrow}</div>
            </>
          ) : (
            <div className="Step--completed">{check}</div>
          )}
          <div
            className={
              !transactionCompleted ? "Step step-current" : "Step completed"
            }
          >
            <h4>Checkout</h4>
          </div>
          <div
            className={!transactionCompleted ? "Step not-completed" : "Step"}
          >
            {arrow}
          </div>
          <div
            className={
              !transactionCompleted ? "Step not-completed" : "Step step-current"
            }
          >
            <h4>Summary</h4>
          </div>
          {!isMobile ? (
            <div className="Checkout--da">
              <img src={da} alt="" />
            </div>
          ) : null}
        </div>
        <div className="Content">
          <div className="Summary">
            <div className="Summary--inner">
              {!transactionCompleted ? (
                <>
                  <div className="Back">
                    <Link to={pathName}>{"< "}Back</Link>
                  </div>
                  <h3>Checkout</h3>
                </>
              ) : (
                <h3>Your subscription</h3>
              )}
              <div className="Summary--list">
                <h5>Order Summary</h5>
                <div className="Item">
                  <h5>Plan:</h5>
                  <h4>{props.plan}</h4>
                </div>
                <div className="Item">
                  <h5>Duration:</h5>
                  <h4>{props.billing} months</h4>
                </div>
                {!transactionCompleted ? (
                  <div></div>
                ) : (
                  <div className="Item">
                    <h5>Next payment:</h5>
                    <h4> {nextPayment} </h4>
                  </div>
                )}
                {!transactionCompleted ? (
                  <div className={`Item ${hasDiscount}`}>
                    <h5>Monthly price:</h5>
                    <h4>
                      {hasDiscount ? <span>€{oldMonthlyPrice}</span> : null}€
                      {monthlyPrice}
                    </h4>
                  </div>
                ) : null}
                <div className="Total-wrapper">
                  {hasDiscount ? (
                    <div className="Item">
                      <h5>Discount:</h5>
                      <h4>{discountAmount}</h4>
                    </div>
                  ) : null}
                  <div className={`Item ${hasDiscount}`}>
                    {!transactionCompleted ? <h5>Total:</h5> : <h5>Paid:</h5>}
                    <h4>
                      {hasDiscount ? <span>€{oldTotalPrice}</span> : null}€
                      {totalPrice}
                    </h4>
                  </div>
                  {firstMpriceState > 0 ? (
                    <div className="Item">
                      <h5>Total today:</h5>
                      <h4>€{firstMpriceState}</h4>
                    </div>
                  ) : (
                    <div></div>
                  )}
                  {downgrade === true ? (
                    <div className="Item">
                      <h5>Next payment:</h5>
                      <h4>{nextBilling}</h4>
                    </div>
                  ) : (
                    <div></div>
                  )}
                </div>
              </div>
              <p>Renews {billingString}, cancel anytime.</p>
            </div>
            {!isMobile ? (
              <div className="Summary--ds">
                <img src={ds} alt="" />
              </div>
            ) : null}
          </div>
          <div className="Billing">
            {!transactionCompleted ? (
              <>
                {/* <div className="Discount">
                  <form onSubmit={handleDiscountSubmit}>
                    <div className={`floating-label ${discountBorder}`}>
                      <input
                        type="text"
                        placeholder=" "
                        onChange={(e) => setDiscount(e.target.value)}
                      />
                      <label>Discount code</label>
                    </div>
                    <div className={`Discount__Apply ${discountLoader}`}>
                      <input type="submit" value="Apply" />
                      <div className={`loader ${discountLoader}`}></div>
                    </div>
                  </form>
                </div> */}
                <h2>Billing Details</h2>
                {/* Stripe Checkout component here */}
              </>
            ) : (
              <div className="Success">
                <h3>Thank you for your subscription!</h3>
                <h4>You can now enjoy following features on TransConnector:</h4>
                <ul>
                  <li>{check}Send unlimited messages</li>
                  {SUBSCRIPTION?.subscriptionName === "Premium" ? (
                    <li>{check}Create tenders</li>
                  ) : (
                    <li>{check}Create tenders and roundtrips</li>
                  )}
                  <li>{check}Unlimited access to all offers</li>
                  <li>{check}Access to companies directory</li>
                </ul>
                <Link to="/dashboard">Continue</Link>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }

  return (
    <Elements stripe={stripePromise} options={{ clientSecret }}>
      <div className="Checkout">
        <div className="Checkout__Navbar">
          <Container>
            <header>
              <Link to="/" className="logo">
                <img src={logo} alt="TransConnector" />
              </Link>
            </header>
          </Container>
        </div>
        <div className="Checkout__Steps">
          {!transactionCompleted ? (
            <>
              <Link to={pathName}>
                <div className="Step--completed">{check}</div>
                <div className="Step completed">
                  <h4>Select Plan</h4>
                </div>
              </Link>
              <div className="Step--arrow">{arrow}</div>
            </>
          ) : (
            <div className="Step--completed">{check}</div>
          )}
          <div
            className={
              !transactionCompleted ? "Step step-current" : "Step completed"
            }
          >
            <h4>Checkout</h4>
          </div>
          <div
            className={!transactionCompleted ? "Step not-completed" : "Step"}
          >
            {arrow}
          </div>
          <div
            className={
              !transactionCompleted ? "Step not-completed" : "Step step-current"
            }
          >
            <h4>Summary</h4>
          </div>
          {!isMobile ? (
            <div className="Checkout--da">
              <img src={da} alt="" />
            </div>
          ) : null}
        </div>
        <div className="Content">
          <div className="Summary">
            <div className="Summary--inner">
              {!transactionCompleted ? (
                <>
                  <div className="Back">
                    <Link to={pathName}>{"< "}Back</Link>
                  </div>
                  <h3>Checkout</h3>
                </>
              ) : (
                <h3>Your subscription</h3>
              )}
              <div className="Summary--list">
                <h5>Order Summary</h5>
                <div className="Item">
                  <h5>Plan:</h5>
                  <h4>{SUBSCRIPTION?.subscriptionName}</h4>
                </div>
                <div className="Item">
                  <h5>Duration:</h5>
                  <h4>{props.billing} months</h4>
                </div>
                {!transactionCompleted ? (
                  <div></div>
                ) : (
                  <div className="Item">
                    <h5>Next payment:</h5>
                    <h4> {nextPayment} </h4>
                  </div>
                )}
                {!transactionCompleted ? (
                  <div className={`Item ${hasDiscount}`}>
                    <h5>Monthly price:</h5>
                    <h4>
                      {hasDiscount ? <span>€{oldMonthlyPrice}</span> : null}€
                      {monthlyPrice}
                    </h4>
                  </div>
                ) : null}
                <div className="Total-wrapper">
                  {hasDiscount ? (
                    <div className="Item">
                      <h5>Discount:</h5>
                      <h4>{discountAmount}</h4>
                    </div>
                  ) : null}
                  <div className={`Item ${hasDiscount}`}>
                    {!transactionCompleted ? <h5>Total:</h5> : <h5>Paid:</h5>}
                    <h4>
                      {hasDiscount ? <span>€{oldTotalPrice}</span> : null}€
                      {totalPrice}
                    </h4>
                  </div>
                  {firstMpriceState > 0 ? (
                    <div className="Item">
                      <h5>Total today:</h5>
                      <h4>€{firstMpriceState}</h4>
                    </div>
                  ) : (
                    <div></div>
                  )}
                  {downgrade === true ? (
                    <div className="Item">
                      <h5>Next payment:</h5>
                      <h4>{nextBilling}</h4>
                    </div>
                  ) : (
                    <div></div>
                  )}
                </div>
              </div>
              <p>Renews {billingString}, cancel anytime.</p>
            </div>
            {!isMobile ? (
              <div className="Summary--ds">
                <img src={ds} alt="" />
              </div>
            ) : null}
          </div>
          <div className="Billing">
            {!transactionCompleted ? (
              <>
                {/* <div className="Discount">
                  <form onSubmit={handleDiscountSubmit}>
                    <div className={`floating-label ${discountBorder}`}>
                      <input
                        type="text"
                        placeholder=" "
                        onChange={(e) => setDiscount(e.target.value)}
                      />
                      <label>Discount code</label>
                    </div>
                    <div className={`Discount__Apply ${discountLoader}`}>
                      <input type="submit" value="Apply" />
                      <div className={`loader ${discountLoader}`}></div>
                    </div>
                  </form>
                </div> */}
                <h2>Billing Details</h2>
                {/* Stripe Checkout component here */}
                {clientSecret && (
                  <CheckoutForm
                    setTransactionCompleted={setTransactionCompleted}
                    setNextPayment={setNextPayment}
                    clientSecret={clientSecret}
                    billing={props.billing}
                    plan={props.plan}
                    price={totalPriceRef.current}
                    subscriptionId={subscriptionId}
                    currentMembershipId={props.currentMembershipId}
                    gatewaySubscriptionId={props.gatewaySubscriptionId}
                  />
                )}
              </>
            ) : (
              <div className="Success">
                <h3>Thank you for your subscription!</h3>
                <h4>You can now enjoy following features on TransConnector:</h4>
                <ul>
                  <li>{check}Send unlimited messages</li>
                  {SUBSCRIPTION?.subscriptionName === "Premium" ? (
                    <li>{check}Create tenders</li>
                  ) : (
                    <li>{check}Create tenders and roundtrips</li>
                  )}
                  <li>{check}Unlimited access to all offers</li>
                  <li>{check}Access to companies directory</li>
                </ul>
                <Link to="/dashboard">Continue</Link>
              </div>
            )}
          </div>
        </div>
      </div>
    </Elements>
  );
};

const arrow = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20.57"
    height="11.086"
    viewBox="0 0 20.57 11.086"
  >
    <g transform="translate(0 0.707)">
      <line
        x2="18.77"
        transform="translate(0 4.898)"
        fill="none"
        stroke="#262a68"
        strokeWidth="2"
      />
      <path
        d="M-15083.424-18445.023l4.834,4.836-4.834,4.836"
        transform="translate(15097.745 18445.023)"
        fill="none"
        stroke="#262a68"
        strokeWidth="2"
      />
    </g>
  </svg>
);

const check = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20.418"
    height="14.348"
    viewBox="0 0 20.418 14.348"
  >
    <path
      d="M-16949.34-18415.387l7.131,7.131,11.166-11.166"
      transform="translate(16950.4 18420.482)"
      fill="none"
      stroke="#00b27e"
      strokeWidth="3"
    />
  </svg>
);

const spinner = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 100 100"
    preserveAspectRatio="xMidYMid"
  >
    <circle
      cx="50"
      cy="50"
      fill="none"
      stroke="white"
      strokeWidth="10"
      r="35"
      strokeDasharray="164.93361431346415 56.97787143782138"
      transform="rotate(275.845 50 50)"
    >
      <animateTransform
        attributeName="transform"
        type="rotate"
        repeatCount="indefinite"
        dur="1s"
        values="0 50 50;360 50 50"
        keyTimes="0;1"
      ></animateTransform>
    </circle>
  </svg>
);

export default withRouter(Checkout);
