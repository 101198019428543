import React, { useState, useEffect } from "react";
import Cookies from "universal-cookie";
import { useSelector, useDispatch } from "react-redux";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import ScrollToTop from "./helpers/ScrollToTop";
import FindRoundtripPage from "./pages/FindRoundtrip";
// import Home from "./pages/Home1";
import Home from "./pages/Home2";
import Login from "./pages/Login";
import Forgot from "./pages/Forgot";
import Register from "./pages/Register";
import VerificationDocuments from "./pages/VerificationDocuments";
import Invited from "./pages/Invited";
import ReactGA from "react-ga";
import { hotjar } from "react-hotjar";
import ReactPixel from "react-facebook-pixel";
import ResetPassword from "./pages/ResetPassword";
import Pricing from "./pages/Pricing";
import FreeTrial from "./pages/FreeTrial";
import Checkout from "./pages/Checkout";
import Tender from "./pages/Tender";
import Roundtrip from "./pages/Roundtrip";
import Spotload from "./pages/Spotload";
import Dashboard from "./pages/Dashboard";
import { CookiesProvider } from "react-cookie";
import { getCompany } from "./store/actions/dashboard/company";
import { getUser, getUserSubscription } from "./store/actions/dashboard/user";
import BlogPage from "./pages/Blog";
import About from "./pages/About";
import LoadUpload from "./pages/LoadUpload";
import Factoring from "./pages/Factoring";
import FAQPage from "./pages/FAQ";
import TermsPage from "./pages/Terms";
import DebtTermsPage from "./pages/DebtTerms";
import DebtPrivacyPolicyPage from "./pages/DebtPrivacyPolicy";
import PrivacyPage from "./pages/Privacy";
import EmptyTruck from "./pages/EmptyTruck";
import CookieNotice from "./components/UserNotice/CookieNotice";
import packageJson from "../package.json";
// import FinancingPage from "./pages/Financing";
import Reservation from "./pages/Reservation";
import VatRefundPage from "./pages/VATRefund";
import EurowagFuel from "./pages/EurowagFuel";
import CargoInsurancePage from "./pages/CargoInsurance";
import CTIPage from "./pages/CTI";
import CMRPage from "./pages/CMR";
import DRIP from "./pages/DRIP";
import DebtCollect from "./pages/debtCollect";
import DebtCollectRegister from "./components/DebtCollectRegister/Register";

//FACEBOOK PIXEL SETUP
const options = {
  autoConfig: true, // set pixel's autoConfig
  debug: false, // enable logs
};

ReactPixel.init("373022400279435", options);

ReactPixel.pageView();

//HOTJAR
hotjar.initialize(1424857, 6);
//GOOGLE ANALYTICS
ReactGA.initialize("UA-122084775-2");
ReactGA.pageview(window.location.pathname + window.location.search);

//Purechat script

const App = () => {
  const [hasCheckedSubscription, setHasCheckedSubscription] = useState(false);
  const USER = useSelector((state) => state.dashboard?.user?.data);
  const COMPANY = useSelector((state) => state.dashboard?.company?.data);
  const SUBSCRIPTION = useSelector(
    (state) => state.dashboard?.user?.subscription
  );
  const dispatch = useDispatch();
  const cookies = new Cookies(),
    token = cookies.get("tc_token");
  // FETCH USER AND COMPANY WHEN TOKEN IS SET
  useEffect(() => {
    if (token && !USER) {
      dispatch(getUser(token));
      if (!COMPANY) {
        dispatch(getCompany(token));
      }
    }
  }, [token, USER, dispatch, COMPANY]);

  useEffect(() => {
    if (!SUBSCRIPTION && !hasCheckedSubscription) {
      dispatch(getUserSubscription());
      setHasCheckedSubscription(true);
    }
  }, [SUBSCRIPTION, hasCheckedSubscription, dispatch]);

  const APP_VERSION = packageJson.version;
  // Refresh if not latest version
  useEffect(() => {
    if (!localStorage.hasOwnProperty("tc_app_version")) {
      localStorage.setItem("tc_app_version", APP_VERSION);
    } else {
      const LOCAL_VERSION = localStorage.getItem("tc_app_version");
      if (LOCAL_VERSION !== APP_VERSION) {
        console.log(
          "The app is not in the latest version. Clearing cache and reloading..."
        );
        localStorage.setItem("tc_app_version", APP_VERSION);
        window.location.reload(true);
      }
    }
  }, [APP_VERSION]);

  // Wait for fetch to complete
  if (token && (!USER || !COMPANY)) return null;
  return (
    <CookiesProvider>
      <Router>
        <div className="App">
          <ScrollToTop />
          <Switch>
            <Route exact path="/" render={() => <Home USER={USER} />} />
            <Route
              path="/companyVerificationTest"
              render={() => void 0}
            />
            <Route
              path="/log-in"
              render={() => <Login USER={USER} COMPANY={COMPANY} />}
            />
            <Route path="/sign-up" render={() => <Register USER={USER} />} />
            {/* <Route path="/dashboard/my-account/verification" render={() => <VerificationDocuments USER={USER} COMPANY={COMPANY} />} /> */}
            <Route exact path="/invited/:id" component={Invited} />
            <Route path="/forgot" render={() => <Forgot USER={USER} />} />
            <Route
              path="/reset-password/:id"
              render={() => <ResetPassword USER={USER} />}
            />
            <Route path="/pricing" render={() => <Pricing USER={USER} />} />
            <Route
              path="/free-trial"
              render={() => <FreeTrial USER={USER} />}
            />
            <Route path="/checkout" render={() => <Checkout USER={USER} />} />
            <Route path="/tender/:id?" render={() => <Tender USER={USER} COMPANY={COMPANY} />} />
            <Route
              path="/roundtrip/:id?"
              render={() => <Roundtrip USER={USER} COMPANY={COMPANY} />}
            />
            <Route
              path="/find-roundtrip/:id?"
              render={() => <FindRoundtripPage USER={USER} COMPANY={COMPANY} />}
            />
            <Route
              path="/spotload/:id?"
              render={() => <Spotload USER={USER} COMPANY={COMPANY} />}
            />
            <Route
              path="/trucks/:id?"
              render={() => <EmptyTruck USER={USER} COMPANY={COMPANY} />}
            />
            <Route path="/blog/:id?" render={() => <BlogPage USER={USER} />} />
            <Route path="/about" render={() => <About USER={USER} />} />
            <Route
              path="/loadupload"
              render={() => <LoadUpload USER={USER} />}
            />
            <Route
              path="/instant-payment"
              render={() => <Factoring USER={USER} />}
            />
            <Route
              path="/VAT-refund"
              render={() => <VatRefundPage USER={USER} />}
            />
            <Route
              path="/eurowag-fuel"
              render={() => <EurowagFuel USER={USER} />}
            />
            <Route path="/drip" render={() => <DRIP USER={USER} />} />
            <Route
              path="/debt-collect"
              render={() => <DebtCollect USER={USER} />}
            />
            <Route
              path="/debt-collect-sign-up"
              render={() => <DebtCollectRegister USER={USER} />}
            />
            <Route path="/faq" render={() => <FAQPage USER={USER} />} />
            <Route path="/terms" render={() => <TermsPage USER={USER} />} />
            <Route
              path="/debtcollect-terms"
              render={() => <DebtTermsPage USER={USER} />}
            />
            <Route
              path="/debtcollect-privacy"
              render={() => <DebtPrivacyPolicyPage USER={USER} />}
            />
            <Route path="/privacy" render={() => <PrivacyPage USER={USER} />} />
            {/* <Route
              path="/financing"
              render={() => <FinancingPage USER={USER} />}
            /> */}
            <Route path="/dashboard" render={() => <Dashboard USER={USER} />} />
            <Route
              path="/ferry-bookings"
              render={() => <Reservation USER={USER} />}
            />
            <Route
              path="/cargo-insurance"
              render={() => <CargoInsurancePage USER={USER} />}
            />
            <Route
              path="/travel-insurance"
              render={() => <CTIPage USER={USER} />}
            />
            <Route path="/CMR" render={() => <CMRPage USER={USER} />} />
          </Switch>
          <ToastContainer newestOnTop />
          {!localStorage.hasOwnProperty("cookie_accept") && <CookieNotice />}
        </div>
      </Router>
    </CookiesProvider>
  );
};

export default App;
