import React, { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { SET_QUOTATIONS } from "../../../store/actionTypes";
import SubMenu from "../Menu/SubMenu";
import { NavLink, Switch, Route, withRouter } from "react-router-dom";

import Loader from "../UI/Loader";
import UserNotice from "../../UserNotice/UserNotice";
import Recieved from "./Recieved";
import Sent from "./Sent";
import Archive from "./Archive";

import welcomeNoResults from "../../../assets/welcome/noResults.svg";
import LeftDrawer from "../Drawers/LeftDrawer";
import { getQuotations } from "../../../store/actions/dashboard/quotation";
import Screen from "../Screen/Screen";
import ScreenBlock from "../Screen/ScreenBlock";
import Navbar from "../Navbar";
import { isBrowser } from "react-device-detect";
import Minimessages from "../Messages/Minimessages";
import DashboardFooter from "../DashboardFooter";

const Quotations = (props) => {
  const { CURRENT_QUOTATIONS, onSetQuotations } = props;
  const dispatch = useDispatch();
  // Fetch quotations
  useEffect(() => {
    if (!CURRENT_QUOTATIONS) {
      dispatch(getQuotations())
        .then((res) => {
          onSetQuotations(res.data);
          if (res.data?.recievedQuotations?.length > 0) {
            props.history.push("/dashboard/quotations/recieved");
          } else if (res.data?.sentQuotations?.length > 0) {
            props.history.push("/dashboard/quotations/sent");
          } else if (res.data?.archiveQuotations?.length > 0) {
            props.history.push("/dashboard/quotations/archive");
          }
        })
        .catch((err) => {
          console.log("err", err);
        });
    }
  }, [CURRENT_QUOTATIONS, onSetQuotations, props.history]);

  // Redirect user to correct page
  useEffect(() => {
    if (CURRENT_QUOTATIONS) {
      if (CURRENT_QUOTATIONS.recievedQuotations?.length > 0) {
        props.history.push("/dashboard/quotations/recieved");
      } else if (CURRENT_QUOTATIONS.sentQuotations?.length > 0) {
        props.history.push("/dashboard/quotations/sent");
      } else if (CURRENT_QUOTATIONS.archiveQuotations?.length > 0) {
        props.history.push("/dashboard/quotations/archive");
      }
    }
    // TODO: DEPENDENCIES MUST BE REFACTORED
  }, []);

  // Return loader while quotations are being fetched
  if (CURRENT_QUOTATIONS?.length <= 0) {
    return (
      <div className="Screen Screen_MyOffers">
        <div className="Screen--inner">
          <div className="Screen--block">
            <Loader />
          </div>
        </div>
      </div>
    );
  }

  // Return User Notice if no quotations were found
  if (
    CURRENT_QUOTATIONS &&
    CURRENT_QUOTATIONS?.recievedQuotations?.length <= 0 &&
    CURRENT_QUOTATIONS?.sentQuotations?.length <= 0
  ) {
    return (
      <>
        <Screen>
          <Navbar />
          <ScreenBlock>
            <UserNotice
              image={welcomeNoResults}
              title="You currently have no quotations to show. Either wait until you recieve some or send one yourself."
              buttons={[
                {
                  name: "Browse tenders",
                  isRouter: true,
                  to: "/tender",
                },
              ]}
            />
          </ScreenBlock>
          {isBrowser && <Minimessages />}
          <LeftDrawer />
          <DashboardFooter />
        </Screen>
      </>
    );
  }

  // Return quotations when quotations were found
  return (
    <>
      <Navbar />
      <Screen>
        <SubMenu>
          {CURRENT_QUOTATIONS?.recievedQuotations?.length > 0 && (
            <NavLink
              activeClassName="active"
              exact
              to="/dashboard/quotations/recieved"
            >
              Recieved ({CURRENT_QUOTATIONS.recievedQuotations?.length})
            </NavLink>
          )}
          {CURRENT_QUOTATIONS?.sentQuotations?.length > 0 && (
            <NavLink activeClassName="active" to="/dashboard/quotations/sent">
              Sent ({CURRENT_QUOTATIONS.sentQuotations?.length})
            </NavLink>
          )}
          {CURRENT_QUOTATIONS?.archiveQuotations?.length > 0 && (
            <NavLink
              activeClassName="active"
              to="/dashboard/quotations/archive"
            >
              Archive ({CURRENT_QUOTATIONS.archiveQuotations?.length})
            </NavLink>
          )}
        </SubMenu>
        <Switch>
          {CURRENT_QUOTATIONS?.recievedQuotations?.length > 0 && (
            <Route path="/dashboard/quotations/recieved" component={Recieved} />
          )}
          {CURRENT_QUOTATIONS?.sentQuotations?.length > 0 && (
            <Route
              path="/dashboard/quotations/sent"
              render={() => <Sent quotations={CURRENT_QUOTATIONS} />}
            />
          )}
          {CURRENT_QUOTATIONS?.archiveQuotations?.length > 0 && (
            <Route
              path="/dashboard/quotations/archive"
              render={() => <Archive quotations={CURRENT_QUOTATIONS} />}
            />
          )}
        </Switch>
      </Screen>
      <LeftDrawer />
      {isBrowser && <Minimessages />}
    </>
  );
};

export const mapStateToProps = (state) => {
  return {
    CURRENT_QUOTATIONS: state.dashboard.quotations.data,
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    onSetQuotations: (payload) =>
      dispatch({ type: SET_QUOTATIONS, payload: payload }),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Quotations));
