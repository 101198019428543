function formatLocationName(locationName) {
    let name = locationName.split(', ')
    if (name[2]) {
        name = name[1] + ', ' + name[2] + ', ' + name[0]
        return name;
    }
    else {
        return locationName
    }
}

export default formatLocationName;