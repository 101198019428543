import styled, { css } from "styled-components";

export const Wrapper = styled.button``;
export const LinkWrapper = styled.div`
  // background: #00ca8f;
  background: ${(props) => props.background ?? "#00ca8f"};
  :hover {
    background: ${(props) => props.hoverBackground ?? "#00ca8f"};
  }
  display: flex;
  max-width: max-content;
  border-radius: 4px;
  a {
    padding: 0.75rem 2.75rem;
    color: #fff;
    font-family: "Inter";
    font-weight: 600;
    text-decoration: none;
    font-size: 18px;
  }
  @media (min-width: 768px) {
    a {
      font-size: 21px;
    }
  }
  ${(props) =>
    props.landingPage &&
    css`
      display: inline-flex;
      padding: 0.625rem 1.25rem;
      justify-content: center;
      align-items: center;
      gap: 0.625rem;
      border-radius: 0.3125rem;
      background: #4faf82;
      transition: 0.3s;
      :hover {
        background: #5ecb98;
      }
      a {
        color: #fff;
        text-align: center;
        font-family: Inter;
        font-size: 1rem;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        padding: 0;
      }
      @media (max-width: 768px) {
        display: flex;
        max-width: none;
      }
    `}
  ${(props) =>
    props.background &&
    css`
      background: ${props.background};
      border: 1px solid ${props.background === "#fff" ? "#262a68" : "#fff"};
      :hover {
        background: ${props.background === "#fff" ? "#F4F8FF" : "#fff"};
      }
      a {
        color: ${props.background === "#fff" ? "#262a68" : "#fff"};
      }
    `}
  ${({ centered }) =>
    centered === "mobile"
      ? css`
          margin-left: auto;
          margin-right: auto;
          @media (min-width: 1200px) {
            margin: unset;
          }
        `
      : centered === "all"
      ? css`
          margin-left: auto;
          margin-right: auto;
        `
      : null}
`;

// v2
export const Button = styled.button`
  color: #fff;
  background: #00b27e;
  font-family: "Inter var", sans-serif;
  font-size: 1rem;
  font-weight: 600;
  align-self: center;
  border: none;
  border-radius: 4px;
  width: fit-content;
  padding: 0.5rem 1rem;
  margin: 1.5rem 0 0;
  transition: 0.25s all ease;
  cursor: pointer;
  &:hover {
    transition: 0.25s all ease;
    background: #07ce94;
    border-color: #07ce94;
  }
`;